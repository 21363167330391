import xhr from './xhr/';
/**
 * 服务记录相关的 API
 */
class ServiceRecordService {
	// 快捷用语
	getShortcuts() {
		return xhr({
			method: 'get',
			url: '/fwjl/kjyy',
		});
	}

	// 新增记录
	addRecord(params) {
		return xhr({
			method: 'post',
			url: '/fwjl/add',
			params,
		});
	}

	// 记录列表
	recordList(params) {
		return xhr({
			method: 'get',
			url: '/fwjl/list',
			params,
		});
	}

	// 获取指定部门下员工
	getDepStaff(id) {
		return xhr({
			method: 'get',
			url: '/fwjl/staff',
			params: {
				bm_id: id,
			},
		});
	}

	// 获取服务管理页的服务汇总列表
	getServiceList(params) {
		return xhr({
			method: 'get',
			url: '/fwjl/fwhz',
			params,
		});
	}

	// 获取服务记录详情
	getServiceDetail(id) {
		return xhr({
			method: 'get',
			url: '/fwjl/xq',
			params: {
				jl_id: id,
			},
		});
	}

	// 提交服务解决办法
	submitServiceSolution(params) {
		return xhr({
			method: 'post',
			url: '/fwjl/tjfa',
			params,
		});
	}

	// 获取咨询服务列表
	getAdvisoryList(params) {
		return xhr({
			method: 'post',
			url: '/zxcj/list',
			params,
		});
	}

	// 添加咨询服务记录
	addAdvisory(params) {
		return xhr({
			method: 'post',
			url: '/zxcj/add',
			params,
		});
	}

	// 获取咨询服务 - 报告名称
	getAdvisoryReportName(params) {
		return xhr({
			method: 'post',
			url: '/zxcj/bgmc',
			params,
		});
	}

	// 获取咨询管理记录
	getAdvisoryManagement(params) {
		return xhr({
			method: 'get',
			url: '/zxcj/fwhz',
			params,
		});
	}

	// 咨询评价
	commentService(params) {
		return xhr({
			method: 'post',
			url: '/zxcj/pj',
			params,
		});
	}

	// 删除
	deleteService(params) {
		return xhr({
			method: 'post',
			url: '/zxcj/sc',
			params,
		});
	}

	// 一日标准健康计划 - 类型
	getDayPlanTypes() {
		return xhr({
			method: 'post',
			url: '/yrjh/lx',
		});
	}

	// 一日标准健康计划 - 新增
	addDayPlan(params) {
		return xhr({
			method: 'post',
			url: '/yrjh/add',
			params,
		});
	}

	// 一日标准健康计划 - 查询详情
	getDayPlan(params) {
		return xhr({
			method: 'post',
			url: '/yrjh/xq',
			params,
		});
	}

	// 一日标准健康计划 - 历史计划
	getDayPlanHistories(params) {
		return xhr({
			method: 'post',
			url: '/yrjh/history',
			params,
		});
	}

	// 一日标准健康计划 - PDF 方案措施
	getPlan(params) {
		return xhr({
			method: 'post',
			url: '/yrjh/facs',
			params,
		});
	}

	// 一日标准健康计划 - 默认计划
	getDayPlanDefault(params) {
		return xhr({
			method: 'post',
			url: '/yrjh/mr',
			params,
		});
	}
}

// 实例化后导出，全局单例
export default new ServiceRecordService();
